/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import 'all/custom/variables.scss';

// Import Bootstrap source files (Required file)
@import 'all/bootstrap/bootstrap.scss';
@import 'all/custom/custom.scss';

//This is for the icons (Required file)
@import 'all/icons/font-awesome/css/fontawesome-all.css';
@import 'all/icons/simple-line-icons/css/simple-line-icons.css';
@import 'all/icons/weather-icons/css/weather-icons.css';
@import 'all/icons/themify-icons/themify-icons.css';
@import 'all/icons/flag-icon-css/flag-icon.min.css';
@import 'all/icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'all/icons/crypto-icons/cryptocoins.css';

.progress-bar
{    
  background-color: transparent!important;

}

svg {color:$secondary!important}

.rc-slider-track, .rc-slider-handle{background-color:$secondary!important ;}

.rbc-toolbar, .rbc-row-content, .rbc-event-label{display: none!important}

.rbc-time-header-content .rbc-time-header-cell-single-day{display: none!important}

.rbc-event-content{padding-top: 0.3rem!important;}

.rbc-today{background-color: transparent!important;}

.event-gen{background-color: #ffb22b!important;}
.event-tem{background-color: #7460ee!important;}
.event-saz{background-color: #fc4b6c!important;}

#sidebarnav > li:last-child{display: none;}

.wbhide{display: none !important;}

.block-picker > div:first-of-type{display: none;}
#rc-editable-input-1{display: none;}

.rc-slider{width: 85% !important;}

@media only screen and (max-width: 730px) {
  #DashboardZoneList {
      display: none;
  }
  .coverImage{float: left}
  .rc-slider{width: 80% !important;}
 
  
}